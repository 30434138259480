.teacher-dashboard {
  margin: 0px !important;
  padding-right: 20px !important;

  .empty-dashboard-teacher {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: #101828;
    padding-top: 30px !important;
  }

  .name {
    color: #007dc6;
  }

  .header-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 24px;
    background: #ffffff;
    box-shadow: 0px 27px 80px rgba(8, 12, 58, 0.04),
      0px 9.85544px 29.2013px rgba(8, 12, 58, 0.0275993),
      0px 4.78464px 14.1767px rgba(8, 12, 58, 0.0222516),
      0px 2.34552px 6.94968px rgba(8, 12, 58, 0.0177484),
      0px 0.927421px 2.74791px rgba(8, 12, 58, 0.0124007);
    border-radius: 16px;

    .header {
      width: 253px;
      height: 32px;
      font-weight: 700;
      font-size: 24px;
      color: #101828;
      white-space: nowrap;
      margin-bottom: 24px;
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      width: 100%;
      margin: 0px !important;
      margin-bottom: 20px !important;

      .MuiList-root {
        width: 100%;
        border: 1px solid #e1dede;
        border-radius: 10px;
        // box-shadow:  #e1dede;
        padding: 0px !important;
      }

      .list-containerone {
        background: #fcfcfd;
        border-radius: 16px;
        padding: 0px 16px !important;
        justify-content: space-between; 
        box-sizing: border-box;
        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          color: #344054;
          overflow-wrap: break-word; 
          word-wrap: break-word;    
          white-space: normal;   
        }
      }

      .list-containertwo {
        padding: 12px 16px !important;

        .title-zero {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          color: #242a32;
        }

        .title-one {
          font-weight: 100;
          font-size: 14px;
          line-height: 20px;
          color: #475467;
        }

        .MuiButton-root {
          padding: 12px 24px !important;
          width: 92px !important;
          height: 48px !important;
          background: #007dc6 !important;
          border-radius: 10px !important;
        }
      }

      .first {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .icon {}
      }

      .second {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .left {
          display: flex;
          flex-direction: column;

          .txt1 {
            font-weight: 700;
            font-size: 20px;
          }

          .txt2 {
            font-weight: 100;
            font-size: 14px;
            line-height: 20px;
            color: #475467;
          }
        }

        .right {
          background-color: #007dc6;
          border-radius: 10px;
          color: white;
          padding: 12px 24px;
        }
      }
    }
  }

  .header-card-two {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // padding: 32px 24px;
    background: #ffffff;
    box-shadow: 0px 27px 80px rgba(8, 12, 58, 0.04),
      0px 9.85544px 29.2013px rgba(8, 12, 58, 0.0275993),
      0px 4.78464px 14.1767px rgba(8, 12, 58, 0.0222516),
      0px 2.34552px 6.94968px rgba(8, 12, 58, 0.0177484),
      0px 0.927421px 2.74791px rgba(8, 12, 58, 0.0124007);
    border-radius: 8px;
    position: relative;

    .MuiLinearProgress-barColorPrimary {
      background-color: #69d584 !important;
    }

    .project-topbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background-color: #007dc6;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;

      .project-topbar-text {
        padding-left: 1%;
        color: white;
      }

      .starts-stops {
        color: white;
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
      }

      .card-main-text-div {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        padding: 10px;
        background-color: #007dc6;
        display: flex;

        .card-main-text {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: white;
        }

        .classbox-url {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
    }

    .project-text-details {
      display: flex;
      align-items: center;
      padding: 1% 1% 0% 1%;

      .project-texts {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: #007dc6;
      }
    }

    .project-header-text {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #344054;
      padding: 1% 1% 0% 1%;
      margin-bottom: 25px;
    }

    .project-subtext {
      font-weight: 100;
      font-size: 14px;
      line-height: 20px;
      color: #475467;
      padding: 1%;
    }

    .mainProgressBarDiv {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;

      .emptyProgressBar {
        background-color: #ffffff;
        border-radius: 100px;
        height: 10px;
        overflow: hidden;
        position: absolute;

        .fillingProgressBar {
          background-image: linear-gradient(to right, #3cd185, #3cd185);
          border-radius: 100px;
          height: 100%;
          width: 100%;
          position: absolute;
        }
      }
    }
  }

  // .titie-grid-card {
  //   padding-bottom: 25px !important;
  // }
  .backimage {
    width: 100%;
    height: 100%;
    border-radius: 14px;
  }

  .card {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    margin-bottom: 10px;
    width: 100%;
    margin-left: 25px;
    margin-right: 25px;

    .content {
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;
      left: 15px;
      right: 0px;
      height: 100%;
      overflow: hidden;
      padding-left: 18px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;

      .content-inside {
        display: flex;
        flex-direction: column;

        .title1 {
          font-family: "Gotham", "Courier New", Courier, monospace !important;
          font-style: normal;
          font-weight: 700;
          font-size: 1.9vw;
          // line-height: 32px;
          color: #ffffff;
          white-space: normal !important;
        }

        .title2 {
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
          color: #e8f6fe;
          font-size: 16px;
          font-style: normal;
          font-weight: 325;
          line-height: 24px;
        }

        .manage-btn {
          width: auto;
          padding: 12px 0px;
          border-radius: 10px;
          font-size: 16px;
          margin-top: 20px;
          color: #00649e;
          font-style: normal;
          max-width: 52%;
          font-weight: 350;
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;

          .exploreIcon {
            margin-left: 16px;
          }
        }
      }

      .icon {
        height: 100%;
        scale: 1.6;
      }
      .card-img0width-sxy10n{
        width: 30%;
      }

    }
  }

  .titie-grid {
    padding: 0px 0px 0px 20px !important;

    .title-header {
      padding-bottom: 0px !important;
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      color: #344054;
      font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
    }
  }

  .project-card {
    .project-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}

.btn-text00xy {
  border-radius: 10px;
  background: #007dc6 !important;
  color: white !important;
  text-decoration: none;
}

.MuiTypography-h6 {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
}

.heading-div-project-project {
  padding: 16px 24px 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .dialouge-header {
    color: #242a32;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
  }

  .close-icon-teacher-project {
    cursor: pointer;
  }
}

.dialouge-text {
  color: #667085 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px;
  font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
}

.dialouge-action-xzy {
  padding: 8px 24px 20px 0px !important;
}

.cancel-btn {
  color: #475467 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 350 !important;
  line-height: 24px !important;
  margin-right: 10px !important;
}

.remove-btn {
  border-radius: 10px !important;
  background: #b42318 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 350 !important;
}

.get-help {
  color: #007DC6;
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  line-height: 24px;
}