.myschool-container {
  margin: 0px !important;
  padding: 0px !important;

  .tag-pending {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 12px;
    border-radius: 24px;
    font-size: 10px;
    line-height: 18px;
    color: #DC6803;
    font-family: "Gotham-medium";
    border-radius: 1000px;
    background: var(--Warning-25, #FEEDE6);
  }

  .card-container {
    margin: 0px !important;

    .bg-txt {
      background-color: #007dc6 !important;
      color: #d2efff !important;
    }

    .card {
      margin: 0px !important;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px 20px;
      gap: 8px;
      background: #ffffff;
      box-shadow: 0px 27px 80px rgba(8, 12, 58, 0.04),
        0px 9.85544px 29.2013px rgba(8, 12, 58, 0.0275993),
        0px 4.78464px 14.1767px rgba(8, 12, 58, 0.0222516),
        0px 2.34552px 6.94968px rgba(8, 12, 58, 0.0177484),
        0px 0.927421px 2.74791px rgba(8, 12, 58, 0.0124007);
      border-radius: 10px;

      .title:hover {
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 38px;
        color: #ffffff;
      }

      .desc {
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        color: #475467;
        font-family: "Gotham-Light", "Courier New", Courier, monospace;
        font-weight: 600;
      }

      .desc:hover {
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        font-family: "Gotham-Light", "Courier New", Courier, monospace;
        font-weight: 600;
      }
    }

    .card:hover {
      margin: 0px !important;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px 20px;
      gap: 8px;
      background: #007dc6;
      box-shadow: 0px 27px 80px rgba(8, 12, 58, 0.04),
        0px 9.85544px 29.2013px rgba(8, 12, 58, 0.0275993),
        0px 4.78464px 14.1767px rgba(8, 12, 58, 0.0222516),
        0px 2.34552px 6.94968px rgba(8, 12, 58, 0.0177484),
        0px 0.927421px 2.74791px rgba(8, 12, 58, 0.0124007);
      border-radius: 10px;

      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 38px;
        color: #ffffff;
      }

      .desc {
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        color: #D2EFFF;
        font-family: "Gotham-Light", "Courier New", Courier, monospace;
        font-weight: 600;
      }
    }
  }

  .space {
    height: 20px;
  }

  .table-container {
    margin: 20px 12px 0px 12px !important;
    padding: 20px 0px 0px 0px;
    background: #ffffff;
    box-shadow: 0px 27px 80px rgba(8, 12, 58, 0.04),
      0px 9.85544px 29.2013px rgba(8, 12, 58, 0.0275993),
      0px 4.78464px 14.1767px rgba(8, 12, 58, 0.0222516),
      0px 2.34552px 6.94968px rgba(8, 12, 58, 0.0177484),
      0px 0.927421px 2.74791px rgba(8, 12, 58, 0.0124007);
    border-radius: 10px;

    .table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding-bottom: 20px;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #242a32;
      }

      .btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        gap: 16px;
        background: #007dc6;
        border-radius: 10px;
        color: #ffffff;
      }
    }
  }
}

.teacher-details-drawer {
  .MuiPaper-root {
    width: 425px;
  }

  .drawerTitle {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    padding-top: 10px;

    .MuiListItemAvatar-root {
      min-width: 48px;
      flex-shrink: 0;
    }

    .user-name {
      font-family: "Gotham", "Courier New", Courier, monospace;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color: #101828;
    }
  }

  .detail_credit_modal {
    padding: 24px 17px;
    background: #f9fafb;
    border: 1px solid #f2f4f7;
    border-radius: 8px;
    width: auto !important;
    margin: 15px 20px;
  }

  .divider {
    width: 100%;
    margin: 15px 0px;
  }

  .credit-details {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .title {
      font-family: "Gotham", "Courier New", Courier, monospace;
      font-style: normal;
      font-weight: 350;
      font-size: 16px;
      line-height: 33px;
      color: #667085;
    }

    .credits {
      font-family: "Gotham", "Courier New", Courier, monospace;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #101828;
    }
  }

  .body {
    display: flex;
    flex-direction: column;

    .title {
      font-family: "Gotham", "Courier New", Courier, monospace;
      font-style: normal;
      font-weight: 350;
      font-size: 16px;
      line-height: 28px;
      color: #667085;
    }

    .credits {
      font-family: "Gotham", "Courier New", Courier, monospace;
      font-style: normal;
      font-weight: 500;
      line-height: 44px;
      color: #101828;
      font-size: 36px;
    }

    .btn-container {
      display: flex;
      padding-top: 20px;

      .add-credit {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        background: #007dc6;
        width: 70%;
        margin-right: 10px;
        color: #ffff;
        border-radius: 10px;
      }

      .reclaim-credit {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        background: #667085;
        width: 30%;
        color: #ffff;
        border-radius: 10px;
      }
    }
  }

  .title-list {
    padding: 10px 20px;
    flex-basis: max-content !important;
    font-size: 18px;
    .title-text {
      font-family: "Gotham", "Courier New", Courier, monospace;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #344054;
    }
  }

  .transactions-list-container {
    padding: 10px 19px;

    /* width */
    .infinite-scroll-component::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    .infinite-scroll-component::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    /* Handle */
    .infinite-scroll-component::-webkit-scrollbar-thumb {
      background: #eaecf0;
      border-radius: 8px;
    }

    /* Handle on hover */
    .infinite-scroll-component::-webkit-scrollbar-thumb:hover {
      background: #eaecf0;
    }

    .transaction-list {
      padding: 0px;
      background: #ffffff;
      border: 1px solid #eaecf0;
      border-radius: 10px;
      overflow: auto;
      height: 500px;

      .list-item-container {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        position: relative;
        box-sizing: border-box;
        text-align: left;
        align-items: center;
        padding-top: 8px;
        padding-bottom: 8px;
        justify-content: space-between;
        text-decoration: none;

        .left {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          width: 70%;
        }

        .title {
          font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
          font-style: normal;
          font-weight: 500;
          font-size: 17px;
          line-height: 24px;
          color: #344054;
        }

        .sec-txt {
          font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          color: #007dc6;
        }
      }
    }
  }
}

.dialog-container-1 {
  .dialog-container .MuiPaper-rounded {
    border-radius: 16px !important;
  }

  .header-main {
    display: flex;
    justify-content: space-between;
  }

  .main-text {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: #242a32;
    padding-top: 16px;
  }

  .sub-text {
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
  }

  .warning-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #007dc6;
    display: flex;
    align-items: center;
    font-family: "Gotham-Medium", "Courier New", Courier, monospace !important;
  }

  .pop-up-input-text {
    font-style: normal;
    font-weight: 350;
    font-size: 16px;
    line-height: 24px;
    font-family: "Gotham-Medium", "Courier New", Courier, monospace !important;
    color: #101828;
  }

  .btn-popup {
    font-family: "Gotham-Medium", "Courier New", Courier, monospace !important;
    font-weight: 700;
  }

  .btn-popup-one {
    font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
    font-weight: 700;
  }

  .input-label {
    ::placeholder {
      font-family: "Gotham-Medium", "Courier New", Courier, monospace !important;
    }
  }

  .footer1 {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;

    .left {
      font-family: "Gotham", "Courier New", Courier, monospace;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #667085;
    }

    .right {
      font-family: "Gotham";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: right;
      color: #101828;
    }
  }

  .desc {
    font-family: "Gotham-Light", "Courier New", Courier, monospace;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #667085;

    .user-name {
      font-family: "Gotham", "Courier New", Courier, monospace;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #667085;
    }
  }

  .reason-string {
    font-family: "Gotham-Light", "Courier New", Courier, monospace;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
  }

  .dialog-actions-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    margin-top: 25px;

    .cancle-btn {
      font-family: "Gotham-Medium", "Courier New", Courier, monospace;
      font-style: normal;
      font-weight: 350;
      font-size: 16px;
      line-height: 24px;
      color: #00649e;
      margin-right: 45px;
    }

    .add-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      background: #b42318;
      border-radius: 10px;
      color: #ffffff;
      font-family: "Gotham-Medium", "Courier New", Courier, monospace;
    }

    .add-btn-1 {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      background: #007dc6;
      border-radius: 10px;
      color: #ffffff;
      font-family: "Gotham-Medium", "Courier New", Courier, monospace;
    }
  }
}

.dialog-container-pending {
  .title-text2 {
    font-size: 30px;
    font-style: normal;
    color: #242a32;
    padding-bottom: 0px;
    font-weight: 700;
    line-height: 38px;
  }
  .content-text2 {
    font-family: "Gotham-light", "Courier New", Courier, monospace;
    color: #667085;
    font-size: 14px;
    line-height: 20px;
    font-style: normal;
    font-weight: 700;
  }
  .modal-title-bold2 {
    .MuiTypography-root {
      font-size: 30px;
      font-family: "Gotham", "Courier New", Courier, monospace !important;
      line-height: 38px;
      color: #242a32;
      font-style: normal;
      font-weight: 700;
    }
  }
  .cancle-btn {
    font-family: "Gotham-Medium", "Courier New", Courier, monospace;
    display: flex;
    flex-direction: row;
    color:#475467;
    cursor: pointer;
    justify-content: center;
    padding: 12px 20px;
    font-size: 16px;
    align-items: center;
    font-weight: 500;
    text-align: left;
    border-radius: 10px;
    line-height: 16px;
  }
  .btn-container-confirm-dialog2 {
    padding-right: 24px;
    padding-bottom: 20px;
  }
  .content-text2 .bold-content-text2 {
    font-family: "Gotham", "Courier New", Courier, monospace !important;
  }
  .content-text-bold {
    font-family: Gotham-medium;
    font-size: 14px;
    color: #101828;
    font-weight: 350;
    padding : 14px 12px 0px 12px;
    line-height: 20px;
    text-align: left;
  }
  .content-text-bold-title {
    font-family: Gotham;
    font-weight: 350;
    color: #101828;
    font-size: 14px;
    text-align: left;
    line-height: 20px;
  }
  .pending-checkout-box {
    border: 1px solid #951111 !important;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .content-text-date {
    padding: 4px 12px 12px 12px;
    font-size: 14px;
    font-family: Gotham-light;
    font-family: Gotham-light;
  }
  .title-cross-box2{
    cursor: pointer;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.teacher_title {
  font-style: normal;
  font-weight: 350;
  font-size: 24px;
  line-height: 32px;
  color: #242A32;
  font-family: "gotham-medium";
}
.request{
  font-style: normal;
  font-weight: 350;
  font-size: 24px;
  line-height: 32px;
  color: #242A32;
  font-family: "gotham-medium";
}
