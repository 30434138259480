.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
    border: 1px solid gray;
    border-radius: 10px}
.invite-teacher-container{
    height: auto;
    flex: 1;
}
::-webkit-scrollbar {
    width: 0px;
}
.first-box{
    width:480px;
    background:white;
    padding:24px 20px 24px 20px;
    border-radius:10px;
    margin-bottom:20px;
}
.network{
    width:440px;
    display:flex;
    justify-content:space-between;
    align-items:center;
}
.network h3{
    color:#344054;
    font-size:24px;
}
.dropdown{
    display:flex;
    justify-content:space-between;
}
.state{
    width: 48%;
}
.district{
    width: 48%;
}
.second-box{
    width:480px;
    background: white;
    padding:24px 20px 24px 20px;
    border-radius:10px;
    margin-bottom:20px;
}
.consent{
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:32px;
}
.dropdown2{
    display:flex;
    justify-content:space-between;
    margin-bottom: 32px;
}
.last-box{
    width:100%;
    display:flex;
    justify-content:flex-start;
    gap:32px;
    align-items:center;
    margin-bottom:50px;
}
