.rowData {
  height: 72px !important;
}
.headertxt{
    padding: 0px;
    margin: 0px !important;
    width: 100%;
    height:100%;
    background-color:#fcfcfd !important;
}
.dashboardtitle{
    color: #101828;
    font-family: Gotham;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-top: 12px !important;
    margin-bottom:12px !important
} 
.chartcard {
    padding: 16px;
    background-color: white;
    border-radius: 10px;
    text-align: center; 
    border: 1px solid #F2F4F7;
    height:558px;
}
.payoutcard {
    padding: 16px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #F2F4F7;
    height: 267px;
}
.chartheading{
    display: flex;
    justify-content: space-between;
}

.payment-box {
    border-radius: 10px; 
    padding: 8px;      
    background-color: #F9FAFB;
    width: 100%;      
    border: 1px solid #F2F4F7;
    display: inline-block; 
    margin-top: 8px;
  }
  
  .amount {
    color: #101828;
    font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
    font-size: 18px;
    font-weight: 350;
    line-height: 22.5px;
    text-align: left;
  }
  
 .payment-date {
    color: #667085;
    margin-top: 8px; 
    font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
    font-size: 12px;
    font-weight: 325;
    line-height: 15px;
    text-align: left;
  }
  .MuiGrid-root.payoutBox.MuiGrid-item{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .earningheading{
    color: #667085;
    font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
    font-size: 16px !important;
    font-weight: bold !important;
    line-height: 24px !important;
    text-align: left;
  }
  .recentpayment{
    text-align: left;
    color: #344054;
    font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 18px;
  }



.dropdown-container {
    position: relative;
    display: inline-block;
  }

  .dropdown-button {
    background-color: #F9FAFB;
    color: #4a5568;
    border: none;
    border-radius: 20px; 
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer; 
    transition: background-color 0.3s ease;
    width: 129px;
    height: 40px;
    font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
    font-size: 16px;
    font-weight: 325;
    color: #667085;
  }
  
  .dropdown-options {
    position: absolute;
    background-color: #F9FAFB;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 10px 0;
    margin-top: 0px;
    width: 100%;
    z-index: 1;
  }
  
  .dropdown-option {
    padding: 8px 8px;
    cursor: pointer;
    text-align: center;
    color: #4a5568;
  }
  
  .dropdown-option:hover {
    background-color: #edf2f7;
  }

  .nodatabox{
    font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
    font-size: 16px;
    font-style: italic;
    font-weight: bold;
    line-height: 24px;
    text-align: left;
    color: #475467;
    margin-top: 24px;
  }
  .totalearning{
    color: #344054;
    font-family: Gotham;
    font-size: 24px;
    font-weight: 350;
    line-height: 32px;
    text-align: left;
    margin-top: 5px;
  }
  