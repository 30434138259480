.filter{
  position: relative;
}
.order_values{
  display: flex;
  gap: 15px;
  width: 100%;
  padding: 12px 10px 12px 10px;
  border: 1px solid #0099F3;
  border-radius: 10px;
  background: #F9FAFB;
  margin-bottom: 10px;
}
.payments_value{
  display: flex;
  gap: 15px;
  width: 100%;
  padding: 12px 10px 12px 10px;
  border: 1px solid #0099F3;
  border-radius: 10px;
  background: #F9FAFB;
  margin-bottom: 10px;
}
.blueBack{
  color: #000;
  background: #0099F3;
}
.payments_value input{
  height: 15px;
  width: 15px;
}
.payments_value span{
  font-size: 14px;
  font-weight: 350;
}
.filter-loader{
  position:absolute;
  top: 50%;
  left:45%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.order_values input{
  height: 15px;
  width: 15px;
}
.order_values span{
  font-size: 14px;
  font-weight: 350;
}
.filter_block {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin-top: 8px;
 width:450px;
 height: auto;
}
.filter_values .order_values:last-child {
  margin-bottom: 0;
}
.orders {
  padding: 16px 10px 16px 10px;
  // background: #FFFFFF;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}
.payments {
  padding: 16px 10px 16px 10px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}
.filter_title {
  font-family: "Gotham", sans-serif;
  font-size: 14px;
  width: 40%;
  padding: 24px 0px 8px 0;
  background: #F2F4F7;
}
.filter_values {
  font-family: "Gotham-Light", sans-serif;
  padding: 24px 16px 24px 16px;
  width: 60%;
}

.orders-table {
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
    height: 200px;
  }

  .orders-table th, .orders-table td {
    padding: 16px 24px;
    text-align: left;
    font-size: 14px;
  }

.orders-table .headings {
    color: #667085;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    padding: 16px, 24px;
    background-color: #F9FAFB;
    border-bottom: 1px solid #EAECF0;
    width: 15%;
}

.orders-table .order-number,
.orders-table .itemsQty-values,
.orders-table .totalPrice-values,
.orders-table .customer-name,
.orders-table .shipByDate {
  width: 15%;
  padding: 8px 24px;
  text-align: left;
}

.orders-table .status-btns .status-btn {
    gap: 16px;
    border-radius: 10px;
    opacity: 1;
  }

  .orders-table .orderNumber-values {
    width: 10%;
    padding: 8px 24px;
    color: #101828;
    background-color: #FFFFFF;
    border-bottom: 1px solid #EAECF0;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
  }
  
  .statusChip {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .statusChip .delivered-btn, .statusChip .shipped-btn, .statusChip, .notShipped-btn, .delayed-btn{
    height: 22px !important
  }
  
  .paymentChip {
    display: flex;
    flex-direction: column;
  }
  
  .paymentChip .paid-btn, .paymentChip .notPaid-btn, .paymentChip .paidViaWarpPay-btn, .paymentChip , .paymentChip .atWarpSpeed-btn {
    height: 22px !important
  }

.orders-table .order-number {
    color: #101828;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    background-color: #FCFCFD;
    border-bottom: 1px solid #EAECF0;
    padding: 8px, 24px;
}

.statusChip .delivered-btn {
    background-color: #F9FAFB;
    color: #101828;
    font-family: Gotham-Light,Courier New,Courier,monospace!important;
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
    text-align: left;
}

.statusChip .shipped-btn {
    background-color: #e9f1ec;
    color: #054F31;
    font-family: Gotham-Light,Courier New,Courier,monospace!important;
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
    text-align: left;
}

.statusChip .notShipped-btn {
    background-color: #f2f3f4;
    color: #101828;
    font-family: Gotham-Light, Courier New, Courier, monospace !important;
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;
    text-align: left;
}

.paymentChip .paid-btn {
    background-color: #DCD3FF;
    color: #150943;
    font-family: Gotham-Light, Courier New, Courier, monospace !important;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    text-align: left;
}

.paymentChip .notPaid-btn {
    background-color: #FEF3F2;
    color: #7A271A;
    font-family: Gotham-Light, Courier New, Courier, monospace !important;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    text-align: left;
}

.paymentChip .paidViaWarpPay-btn {
    background-color: #DCD3FF;
    color: #150943;
    font-family: Gotham-Light, Courier New, Courier, monospace !important;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    text-align: left;
}

.statusChip .delayed-btn {
    background-color: #FECDCA;
    font-family: Gotham-Light, Courier New, Courier, monospace !important;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    text-align: left;
    color: #7A271A;
}

.paymentChip .atWarpSpeed-btn {
    background-color: #32D583;
    color: #054F31;
    font-family: Gotham-Light, Courier New, Courier, monospace !important;
    font-size: 12px;
    font-style: italic;
    font-weight: bold;
    line-height: 18px;
    text-align: left;
}

.status-btns .status-btn {
    gap: 8px;
    border-radius: 10px;
    opacity: 1;
    pointer-events: none;
}

.orders-table .totalPrice-values {
    background-color: #FFFFFF;
    border-bottom: 1px solid #EAECF0;
    padding: 8px, 24px;
    color: #667085;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.orders-table .customer-name{
    background-color: #FFFFFF;
    border-bottom: 1px solid #EAECF0;
    padding: 8px, 24px;
    color: #667085;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.orders-table .shipByDate{
    background-color: #FFFFFF;
    border-bottom: 1px solid #EAECF0;
    padding: 8px, 24px;
    color: #667085;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.orders-table .itemsQty-values {
    background-color: #FFFFFF;
    border-bottom: 1px solid #EAECF0;
    padding: 8px, 24px;
    color: #101828;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}

.orders-table .orderNumber-values {
    background-color: #FFFFFF;
    gap: 12px;
    border-bottom: 1px solid #EAECF0;
    padding: 8px, 24px;
    color: #101828;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.orders-table .status{
  border-bottom: 1px solid #EAECF0;
  padding: 8px 24px;
}

.filter{
  font-family: Gotham-Light, Courier New, Courier, monospace !important;
  font-weight: bold;
  font-size: 16;
  color: #667085;
  margin-top: 3px;
}
.orderLength{
  margin-left: 10px;
  margin-top: 3px;
  color: #667085;
  font-family: Gotham-Light, Courier New, Courier, monospace !important;
  font-size: 16px;
  font-weight: 325;
  line-height: 20px;

}
.orderDiv{
  display: flex;
  align-items: center;
  justify-content: center;
}
.error{
  font-family: Gotham-Light, Courier New, Courier, monospace !important;
  font-weight: bold !important;
  color: red;
}
.table-row-headings{
  height: 44px !important;
}
.rowData{
  height: 72px !important;
}
.analyticsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
}

.analyticsBox {
  width: 32.5%;
  padding: 16px;
  background-color: #FFFFFF;
  border-radius: 10px;
  align-items: flex-start;
  border-width: 2px;
  border-color: #F2F4F7;
  border-style: solid;
}

.analyticsTitle {
  font-size: 24px;
  font-weight: 300;
  font-family: 'Gotham', sans-serif;
  line-height: 32px;
  color: #344054;
}

.analyticsValue {
  font-family: 'Gotham', sans-serif;
  font-weight: 700;
  font-size: 48px;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 60px;
  color: #101828;
}

.analyticsLink {
  font-family: 'Gotham-Light', sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #007DC6;
  text-decoration: none;
  width: fit-content;
  cursor: pointer;
}
.calendar-container {
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
}

.date-label {
  font-size: 14px;
  color: #69737e;

}

.custom-date-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.custom-date-input::placeholder {
  color: #c4c4c4;
}

.custom-calendar {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.react-datepicker__header {
  background-color: transparent;
  border-bottom: none;
}
.react-datepicker{
  border:none;
  box-shadow: 0px 0px 20px 4px #BFBFBF40;

}
.react-datepicker__current-month {
  font-size: 16px;
  color: #454545;
  margin-bottom: 20px;
  font-family: Gotham;
  font-weight: 350;

}

.react-datepicker__day-names{
  display: flex;
  justify-content: space-around;
    font-family: "Gotham-Light", "Courier New", Courier, monospace;
    font-size: 14px;
    font-weight: bold;
    color: #737373 !important;
}
.react-datepicker__week {
  display: flex;
  justify-content: space-around;
}

.react-datepicker__day {
  width: 36px;
  line-height: 36px;
  font-size: 14px;
  color: #333;
  margin: 2px;
  border-radius: 50%;

}

.react-datepicker__day--outside-month {
  color: #dcdcdc;
}

.react-datepicker__day--selected{
  background-color: #0099F3;
  color: white;
  border-radius: 50%;
}
.react-datepicker__day:hover {
  background-color: #0099F3;
  color: white;
  border-radius: 50%;
}
.react-datepicker__day--today:hover{
  background-color: #0099F3;
  color: white;
  border-radius: 50%;
}
.react-datepicker__day--today.react-datepicker__day--selected {
  background-color: #0099F3;
  color: white;
  border-radius: 50%;
}
.react-datepicker__day--today{
  background-color: white;
  color: black;
  border-radius: 50%;
}
.react-datepicker__navigation--next{
  right: 2px;
  margin-top: 20px;
  margin-right: 12px;
}
.react-datepicker__navigation--previous{
  left: 2px;
  margin-top: 20px;
  margin-left: 12px;
}
.react-datepicker__day--keyboard-selected{
  background-color: white;
}
.calendar-container {
  position: relative;
}

.date-picker-wrapper {
  position: relative;
  display: inline-block;
}

.custom-date-input {
  padding-right: 30px; /* Space for the calendar icon */
}

.calendar-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none; 
  color: #555;
}