.drawer-main {
  display: flex;
  height: inherit;
 

  .MuiDrawer-paperAnchorDockedLeft {
    .MuiList-padding {
      // padding: 8px !important;
      .tab-icon-close {
        min-width: 56px !important;
        padding-left: 0px !important;
      }
    }
  }

  .MuiTypography-body1 {
    font-family: "Gotham-Medium", "Courier New", Courier, monospace !important;
    font-weight: 500 !important;
    font-size: 17px !important;
    line-height: unset !important;
    letter-spacing: unset !important;
  }

  .icon-image path {
    color: red;
  }

  .bg-gray {
    background: #f9fafb;
  }

  .popover-list {
    padding: 0px !important;

    .MuiListItem-root {
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 238px;
    }

    .MuiList-padding {
      padding: 0px !important;
    }


    .list-des {
      font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      color: #007dc6;
      cursor: pointer;
    }
  }

  .tab-icon-close {
    min-width: 56px !important;
    padding-left: 9px;
  }

  .tab-icon-open {
    min-width: 33px !important;
  }

  .drawer-link-txt {
    font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #475467 !important;
  }

  .drawer-link-txt-1 {
    font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #00649e !important;
  }

  .drawer-footer {
    position: absolute;
    bottom: 0px;

    .MuiPaper-elevation8 {
      left: 10px !important;
      bottom: 72px !important;
      top: unset !important;
    }

    .card-close {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column-reverse;
      width: 67%;

      .desc1 {
        background: #e8f6fe;
        width: 47px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      .desc2 {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
      }

      .desc {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        align-items: center;
        background: #f9fafb;
        height: 172px;
        padding: 0px 8px;
        border-radius: 10px;

        .credits {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          color: #007dc6;
          writing-mode: tb-rl;
          transform: rotate(-180deg);
          font-family: "Gotham-bold", "Courier New", Courier, monospace !important;
        }

        .credit-title {
          font-style: normal;
          font-size: 20px;
          line-height: 30px;
          color: #007dc6;
          writing-mode: tb-rl;
          transform: rotate(-180deg);
          font-weight: 700;
          font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
        }
      }
    }

    .avtar {
      border: 1px solid #d0d5dd;
      margin-left: -5px;

      .avtar-fallback {
        color: #bdbdbd !important;
      }
    }

    .MuiListItemAvatar-root {
      min-width: 40px !important;
    }

    .MuiAvatar-root {
      width: 35px;
      height: 35px;
      cursor: pointer;
    }

    .MuiListItem-gutters {
      padding-right: 0px;
    }

    .account-title {
      font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #475467;
      cursor: pointer;
    }

    .role-title {
      font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
      color: #007dc6;
    }

    .card1 {
      height: auto !important;
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      width: 220px;
      height: 123px;
      border-radius: 10px;

      .add-credit-btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        gap: 16px;
        width: 220px;
        height: 48px;
        background: #e8f6fe;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        color: #007dc6;
        font-weight: 700;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      .card-body1 {
        border-radius: 10px !important;
      }

      .card-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 220px;
        grid-gap: 0px !important;
        height: 75px;
        background: #eaecf0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-image: url("./card-credit.svg");
        background-repeat: no-repeat;
        background-size: cover;

        .title {
          font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #475467;
          padding-top: 14px;
          padding-left: 14px;
          padding-bottom: 3px;
        }

        .desc {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 14px;

          .credits {
            font-family: "Gotham-bold", "Courier New", Courier, monospace !important;
            font-style: normal;
            font-size: 20px;
            line-height: 30px;
            color: #007dc6;
            padding-right: 10px;
          }

          .credit-title {
            font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            color: #007dc6;
          }
        }
      }
    }

    .logout-btn-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 15px;

      .btn {
        background: #007dc6 !important;
        color: white;
      }
    }
  }
  @media only screen and (max-height: 545px) {
    .drawer-footer {
      position: unset;
    }
  }
  .active {
    background-color: #d2efff !important;
    border-radius: 8px;
    margin: 4px 0px;
  }

  .non-active {
    border-radius: 8px;
    margin: 4px 0px;
  }

  .icon-close-open {
    position: inherit !important;
  }

  .right-open-icon {
    position: absolute;
    left: 63px;
    top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 26px;
    height: 19px;
    background: #fcfcfd;
    border: 4px solid #ffffff;
    border-radius: 6px;
  }
}

.loader-title {
  font-family: "Gotham", "Courier New", Courier, monospace !important;
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  letter-spacing: -0.02em;
  color: #004b77;
}

.drawer-link-txt-vendor {
  font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
  font-style: normal !important;
  font-weight: 100 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #98A2B3 !important;
}
.drawer-link-txt-1-vendor{
  font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color:#FFFFFF !important;
}
.active-vendor {
  background-color:#344054 !important;
  border-radius: 8px !important;
  margin: 4px 0px !important;
}

.orange-dot{
  border: 6px solid #FEEDE6;
  width : 22px;
  height: 22px;
  background-color: #f3814c;
  border-radius: 50%;
  display: inline-block;
}
.MuiDivider-root{
  background-color: #EAECF0 !important;
}
.no-border{
  border:none !important;
}
.logoutCard{
  box-shadow: 0 0 2px grey !important;
  border-radius: 10px;
}
.mode{
   border-bottom: 2px solid rgb(229, 229, 229);
  display: flex;
  padding: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.list-title {
  font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #475467;
      font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
      cursor: pointer;
}
.mode:hover{
  background-color: #f6f7f8 !important;
}
.profileName:hover{
  background-color: #f6f7f8 !important;
}