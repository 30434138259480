.filterstatus {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
  padding: 0 20px;
}

.filter-label-container {
  display: inline-block;
}

.filter-label {
  background-color: #D2EFFF;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #00649E;
  font-family: Gotham;
  font-weight: 325;
}

.remove-filter {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-left: 5px;
  color: #00649E;
}
.vendor-shipment-container {
  width: 800px;
  height: 1024px;
  margin: auto;
  padding: 40px 24px;
  background-color: #FFFFFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.order-header-text {
  font-family: Gotham;
  font-size: 30px;
  font-weight: 350;
  line-height: 38px;
  text-align: left;
  margin-bottom: 30px;
  color: #101828;
}

.shipment-actions {
  display: flex;
  justify-content: flex-start;
  gap: 0px;
  margin-bottom: 30px;
}

.action-item1{
  padding: 8px 10px;
  display: flex;
  gap: 10px;
  width:140px;
  align-items: center;
}
.action-item3{
  padding: 8px 10px;
  display: flex;
  gap: 10px;
  width:170px;
  align-items: center;
}


.action-item2{
  display: flex;
  gap: 10px;
  background-color: #007DC6;
  padding: 8px 10px;
  border-radius: 8px;
  align-items: center;
  height: 40px;
  width:180px;
}
.actionImg1{
  width: 24px;
  height: 24px;
  background-color: #248FCE;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.action-item4{
  display: flex;
  gap: 10px;
  background-color: #007DC6;
  padding: 8px 10px;
  border-radius: 8px;
  align-items: center;
  height: 40px;
  width:150px;
}

.packingListIconImg {
  width: 14px;
  height: 20px;
  gap: 0px;
  opacity: 1px;
}

.packingListText {
  color: #475467;
  font-family: Gotham;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.shipmentDetailsIconImg {
  width: 14px;
  height: 14px;
  gap: 0px;
  opacity: 1px;
}

.shipmentDetailsIconText {
  color: #FFFFFF;
  font-family: Gotham;
  font-size: 14px;
  font-weight: 350;
  line-height: 20px;
  text-align: left;
}

.vendor-shipment-section {
  margin-bottom: 26px;
}

.vendor-shipment-section label {
  color: #101828;
  gap: 10px;
  font-family: Gotham;
  font-size: 16px;
  font-weight: 350;
  line-height: 20px;
  text-align: left;
}

.vendor-shipment-section input,
.vendor-shipment-section select {
  background-color: #FFFFFF;
  width: 100%;
  margin-top: 5px;
  padding: 12px;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #F2F4F7 !important;
  opacity: 1px;
}

.vendor-shipment-section input:focus{
  outline: none;
}

.vendor-shipment-actions {
  display: flex;
  justify-content: space-between;
}

.vendor-shipment-actions-btn {
  display: flex;
  justify-content: flex-end;
}

.vendor-shipment-actions-btn button:first-child {
  background-color: #F2F4F7;
  color: #667085;
  font-family: Gotham;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  padding: 12px 24px;
  border-radius: 10px;
   cursor: pointer;
   height: 48px;
}

.vendor-shipment-actions-btn button:last-child {
  background-color: #007DC6;
  color: #FFFFFF;
  font-family: Gotham;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  padding: 12px 24px;
  border-radius: 10px;
  margin-left: 8px;
  height: 48px;
}
.orderHeader{
  display: flex;
  justify-content: space-between;
}
.custom-close-button {
  background-color: #FFFFFF;
  border: none;
  border-radius: 50%; 
  width: 32px;
  height: 32px; 
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); 
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
}

.custom-close-button:hover {
  background-color: #e0e0e0;
}
.headingShipment{
    font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
    font-size: 16px;
    font-weight: 350;
    line-height: 20px;
    text-align: left;
    color: #101828;
}
.shipmentInput{
  font-family: Gotham-Light,Courier New,Courier,monospace!important;
  font-size: 16px;
  font-weight: 325;
  line-height: 20px;
  text-align: left;
  color: #667085;
  font-weight: bold;
}
.MuiTable-root.order-table
{
  border: 1px solid #EAECF0 !important;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
  overflow: hidden;
}
.tableHeading{
  background-color: #F9FAFB;
}
.table-row-heading th{
  color: #667085;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
}
.orderName{
  color: #101828 !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}
.orderQty{
  color: #667085 !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}