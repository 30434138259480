.standards-selection-container {
  height: 100vh;
  width: 100vw;
  background-color: #d2efff;
  position: relative;
  display: flex;
  overflow-y: scroll;
  scrollbar-width: none;
  .logo-icon {
    position: absolute;
    top: 15px;
    left: 50px;
  }
  .left-container {
    margin-top: 50px;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 14% 0px 10%;
    .step-txt {
      color: #004b77;
      font-family: "Gotham", "Courier New", Courier, monospace;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px;
      letter-spacing: -0.72px;
      opacity: 0.20000000298023224;
    }
    .title-txt {
      color: #004b77;
      font-family: "Gotham", "Courier New", Courier, monospace;
      font-size: 72px;
      font-style: normal;
      font-weight: 700;
      line-height: 90px;
      letter-spacing: -1.44px;
    }
    .description {
      color: #007dc6;
      font-family: "Gotham-Medium", "Courier New", Courier, monospace;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
    }
    .btn-container-div {
      display: flex;
      justify-content: flex-start;
      margin-top: 20px;
      .back-btn-step {
        background: #fff;
        border-radius: 8px;
        padding: 8px 12px;
        display: flex;
        align-items: center !important;
        justify-content: center;
        color: #98a2b3;
        font-family: "Gotham-Medium", "Courier New", Courier, monospace;
      }
      .go-to-dashboard {
        background: #fff;
        border-radius: 8px;
        padding: 8px 12px;
        display: flex;
        align-items: center !important;
        justify-content: center;
        gap: 16px;
        color: #00649e;
        font-family: "Gotham-Medium", "Courier New", Courier, monospace;
      }
    }

    .recommendation-container {
      width: 480px;
      height: 108px; 
      margin-top: 100px;
      background:#EBF8FF;
      border-radius: 16px;
      padding: 14px;
      display: flex;
      align-items: center;
      gap: 18px;

      .recommendation-text {
        color: #007DC6;
        font-family: "Gotham", "Courier New", Courier, monospace;
        font-size: 16px;
        font-weight: 350;
        line-height: 20px;
        height: 60px;
        width: 161.9px;
      }

      .recommendation-profile {
        display: flex;
        align-items: center;
        width: 300px;
        height: 50px;

        .teacher-img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          margin-right: 8px;
        }

        .teacher-info {
          display: flex;
          flex-direction: column;
          padding-right: 10px;

          .teacher-name {
            width: 113px;
            height: 30px;
            font-family: "Gotham", "Courier New", Courier, monospace;
            font-size: 20px;
            font-weight: 350;
            line-height: 30px;
            color: #344054;
            text-wrap: nowrap;
          }

          .teacher-title {
            font-family: "Gotham", "Courier New", Courier, monospace;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: #475467;
          }
        }
      }
    }

  }
  .right-container {
    width: 50%;
    height: 100%;
    margin-right: 2%;
    overflow: hidden;
    margin-top: 60px;
    .infinite-scroll-container {
      height: 100vh;

      .cards-container::-webkit-scrollbar {
        width: 10px;
        height: 5px;
      }

      /* Track */
      .cards-container::-webkit-scrollbar-track {
        border-radius: 10px;
      }

      /* Handle */
      .cards-container::-webkit-scrollbar-thumb {
        background: white;
        border-radius: 8px;
      }

      /* Handle on hover */
      .cards-container::-webkit-scrollbar-thumb:hover {
        background: white;
      }
      .cards-container {
        height: 100vh;
        padding: 20px 12px;
        background-color: #d2efff;
        display: flex;
        flex-direction: column;
        overflow: scroll;
        .card-selection {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 25px;
          align-items: center;
          border-radius: 16px;
          background: #fff;
          margin-bottom: 12px;
          box-shadow: 0 0 10px #d2efff !important;
          .card-content {
            display: flex;
            justify-content: flex-start;
            width: 92%;
            .profile-img {
              border-radius: 12px;
              border: 2px solid #eaecf0;
              height: 10vw;
              width: 10vw;
              margin-right: 20px;
            }
            .profile-details {
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              align-items: flex-start;
              .standard-title {
                color: #004b77;
                font-family: "Gotham", "Courier New", Courier, monospace;
                font-size: 36px;
                font-style: normal;
                font-weight: 700;
                line-height: 44px;
                letter-spacing: -0.72px;
              }
              .tag-container {
                border-radius: 10px;
                padding: 10px;
                background: #f9fafb;
                color: #475467;
                font-family: "Gotham-Light", "Courier New", Courier, monospace;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
              }
            }
          }
          .selected {
            .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
              background-color: #0099f3 !important;
            }

            .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
              opacity: initial !important;
            }
            .MuiSwitch-track {
              width: 100%;
              height: 100%;
              z-index: -1;
              transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
              border-radius: 16px;
            }
            .MuiSwitch-switchBase {
              top: 5px;
              left: 8px;
              color: #fff;
              z-index: 1;
              position: absolute;
              transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            }
            .MuiSwitch-root {
              width: 72px;
              height: 49px;
              display: inline-flex;
              padding: 12px;
              z-index: 0;
              overflow: hidden;
              position: relative;
              box-sizing: border-box;
              flex-shrink: 0;
              vertical-align: middle;
            }
          }
          .unselected {
            .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
              background-color: #0099f3 !important;
            }

            .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
              opacity: initial !important;
            }
            .MuiSwitch-root {
              width: 72px;
              height: 49px;
              display: inline-flex;
              padding: 12px;
              z-index: 0;
              overflow: hidden;
              position: relative;
              box-sizing: border-box;
              flex-shrink: 0;
              vertical-align: middle;
            }
            .MuiSwitch-track {
              width: 100%;
              height: 100%;
              opacity: 1;
              z-index: -1;
              border-radius: 16px;
              background-color: #f2f4f7;
            }
            .MuiSwitch-switchBase {
              top: 5px;
              left: 5px;
              color: #fff;
              z-index: 1;
              position: absolute;
              transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            }
          }

          .toggle-btn {
          }
        }
      }
    }
  }
}


