.support-container {
  margin: 0px !important;
  padding: 0px 20px !important;
  padding-right: 0px !important;
  display: flex;
  flex-direction: column;
  .min-h-340 {
    min-height: 340px;
    font-weight: 325;
    font-size: 20px;
    line-height: 24px;
    font-family: "GOTHAM-Medium1" "Courier New", Courier, monospace;
  }
  .suport-content {
    display: flex;
    min-height: 90vh;
    width: inherit;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
  }
  .pt-20 {
    padding-top: 38px;
  }

  .pb-10 {
    padding-bottom: 10px;
  }

  .header1 {
    .img {
      width: 100%;
      height: 100%;
    }

    .content-1 {
      height: auto;
      width: auto;
      position: relative;

      .content {
        position: absolute;
        top: 16%;
        bottom: 16%;
        left: 2%;
        width: 59%;

        .pad {
          padding-bottom: 3.2552083333333335vw;
        }

        .pad1 {
          padding-bottom: 2.255vw !important;

        }

        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 3.125vw;
          line-height: unset !important;
          letter-spacing: -0.02em;
          color: #00324f;
        }

        .desc { 
          color: #007DC6;
          font-family: Gotham;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
        }
      }
    }
  }

  .content {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 3.5vw;
      letter-spacing: -0.02em;
      color: #00324f;
    }

    .desc {
      font-weight: 100;
      font-size: 1.5vw;
      color: #475467;
    }
  }
}

.back-container {
  padding: 10px 0px;
  .img-div {
    padding: 10px;
  }

}

.blogs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #242a32;
  }
  .top-title {
    font-family: Gotham;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: #242a32 !important;
    padding-left: 8px;
  }
  .menu {
    font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
    font-size: 16px;
    line-height: 24px;
    color: #007dc6;
    padding-top: 24px;
    padding-left: 8px;
    width: fit-content;
  }
  .seeAll{
    font-style: normal;
    font-family: Gotham;
    font-size: 16px;
    line-height: 24px;
    color: #007dc6;
    padding-top: 24px;
    padding-left: 8px;
    display: flex;
    align-items: center;
  }
  .seeAll:hover{
    font-style: normal;
    font-weight: 100;
    font-size: 16px;
    line-height: 24px;
    color: #007dc6;
    padding-top: 24px;
    cursor: pointer;
    padding-left: 8px;
    text-shadow: 2px 2px 4px rgba(134, 133, 133, 0.5);
    width: fit-content;
  }
  .menu:hover {
    font-family: "Gotham-Medium1", "Courier New", Courier, monospace;
    font-size: 16px;
    line-height: 24px;
    color: #007dc6;
    padding-top: 24px;
    cursor: pointer;
    text-shadow: 2px 2px 4px rgba(134, 133, 133, 0.5);
    padding-left: 8px;
    width: fit-content;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  width: inherit;
  align-self: flex-end;

  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    width: 500px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #52525226;
    margin-bottom: 10px;
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #242a32;
    }

    .desc {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #007dc6;
    }

    .btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .supportbtn {
        padding: 12px 24px;
        gap: 16px;
        height: 48px;
        background: #007dc6;
        border-radius: 10px;
        color: #ffffff;
        width: 49%;
        font-size: 16px;
        font-family: "Gotham-Light", "Courier New", Courier, monospace;
        font-weight: 700;
      }
    }
  }
}
.contentTitle{
font-family: Gotham;
font-size: 32px;
font-weight: 700;
line-height: 28px;
color:black;
margin-bottom: 10px;

}
.contentsupprot p {
  margin: 0 0 10px; 
  font-family: Gotham;
  font-size: 20px;
  font-weight: 325;
  line-height: 30px;
  width:100%;
  font-weight: bold;
  color:black;
  font-family: "Gotham-Light", "Courier New", Courier, monospace;
}
.contentsupprot {
  margin-top: 25px;
  box-shadow: 0px 2px 13px 0px #0000001F;
  padding: 20px;
  border-radius: 20px;
  overflow: hidden; 
  word-wrap: break-word;
  word-break: break-word;
  max-width: 100%;
}
.articalMain{
  padding: 50px 20px 20px 20px;

  .articaldiv{
    width: 95%;
    margin-top: 20px;
  }
  .articalimg{
    height: 153px;
    width: 100%;
  }
  .articalName{
    box-shadow: 0px 0px 10px 0px #A7A7A726;
    cursor: pointer;
    height: 125px;
    width: 95%;   
   padding: 24px 20px 24px 20px;
   border-radius: 10px;
   margin-top: 15px;
   margin-bottom: 30px;
  }
  .articalName:hover{
    box-shadow: 0px 0px 10px 0px #31313126;
  }
  .articaltitle{
  font-family: Gotham !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 32px !important;
  color: #004B77;
  }
  .discrption{
  font-family: "Gotham-Light", "Courier New", Courier, monospace;
  font-weight: bold !important;
  font-size: 15px;
  font-weight: 325;
  line-height: 30px;
  color: #000000;
  margin-top: 15px;
  }
 .supportbread{
  font-family: "GOTHAM-Medium1" "Courier New", Courier, monospace;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #475467;
  cursor: pointer;
 }
 .supportartical{
  color: #00649E;
  font-family: "GOTHAM-Medium1" "Courier New", Courier, monospace;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
 }
 .drift-controller-icon--active{
  background: #007DC6 !important;
  }
}
