.addcredit-container {
  margin: 0px !important;
  padding: 0px !important;
  width: auto !important;
  .desc {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #344054;
    font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
  }

  .card0 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;

    .left {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }

    .right {
      display: flex;
      justify-content: flex-end;
    }

    .btn-plus {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 24px;
      height: 48px;
      background: #007dc6;
      border-radius: 10px;
      color: #ffffff;
    }



    .number {
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      color: #ffffff;
    }

    .btn-text {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      text-decoration: none;
      font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;

    }

    .apply-credit0101 {
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

    }
  }

  .card-item-main {
    margin: 0px !important;
    padding: 0px !important;
    margin-top: 6px !important;

    .card-item {
      border: 1px solid #eaecf0;
      border-radius: 10px;
      position: relative;
      padding: 10px;
      background-color: white !important;

      .discount {
        position: absolute;
        right: 0px;
        top: 0px;
        background-color: #feede6;
        padding: 12px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
        color: #f5814d;
        font-style: normal;
        font-weight: 400;
        font-family: "Gotham-Medium", "Courier New", Courier, monospace !important;
      }

      .img {
        margin-bottom: 25px;
      }

      .card-main-text {
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #344054;
      }

      .card-sub-text {
        font-weight: 100;
        font-size: 18px;
        line-height: 28px;
        color: #667085;
        font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
      }

      .card-btn-text {
        width: 100%;
        align-items: center;
        padding: 12px 24px;
        height: 40px;
        background: #007dc6;
        border-radius: 10px;
        color: #ffffff;
      }

      .card-btn-text1 {
        margin-top: 10px;
        width: 100%;
        align-items: center;
        padding: 12px 24px;
        height: 40px;
        border-radius: 10px;
        color: #007dc6;
        font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
      }
    }
  }

  .card-item-main2 {
    margin: 0px 0px;

    table: {
      border-spacing: 0px 4px;
    }
    .credit-text {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #344054;
      font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
    }

    .credit-amt {
      font-weight: 100;
      font-size: 16px;
      line-height: 24px;
      color: #344054;
    }

    .credit-date {
      font-weight: 100;
      font-size: 16px;
      line-height: 24px;
      color: #98a2b3;
      font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
    }

    .download-btn {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #007dc6;
      font-family: "Gotham-Medium1", "Courier New", Courier, monospace !important;
    }

    .image-container {
      position: relative;

      .image {
        width: 100%;
        border-radius: 8px;
      }

      .back-img {
        position: absolute;
        top: 2%;

        .card-image-main-text {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
          color: #004b77;
          padding: 25px;
        }

        .card-image-sub-text {
          font-weight: 100;
          font-size: 16px;
          line-height: 24px;
          color: #007dc6;
          font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
          padding: 25px;
        }

        .btn-image-main {
          background: #007dc6;
          border-radius: 10px;
          color: #ffffff;
          margin-left: 25px;
        }
      }
    }
  }
}




.dialog-title {
  background-color: #E8F6FF;
  padding: 16px;
}
.closeImage{
  display: flex;
  justify-content:end;
}
.closeImage img {
  background-color: white;
  border-radius: 50%; 
  padding: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.box{
  margin-top: -20px !important;
  margin-bottom: 5px !important;
}
.dialog-title-text {
  font-family: "Gotham", "Courier New", Courier, monospace !important;
  font-size: 22px;
  font-weight: 600;
  color: #007DC6;
  margin-top: 5px !important;
}

.credit-info {
  font-size: 18px;
  color: #007DC6;
}

.dialog-content {
  padding: 24px 32px;
}

.titlecredit{
  font-family: "Gotham", "Courier New", Courier, monospace !important;
  font-size: 30px;
  font-weight: 700;
  margin-top: 10px !important;
  color:#242A32;
  margin-top: 32px !important;
}
.creditdis{
  margin-top: 16px !important;
  color: #475467;
  margin-bottom: 32px !important;
  max-width:510px;
  font-family: "Gotham-Light", "Courier New", Courier, monospace !important;
  font-weight: bold !important;
}

.row-container {
  display: flex;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
}

.input-field {
  flex: 1;
}

.dialog-actions {
  justify-content: start;
  padding-right: 0px !important;
  padding-top:32px !important;
}

.request-button {
  padding: 12px 32px; 
  font-size: 16px;
  background-color: #007BFF !important;
  color: #fff !important;
  text-transform: none;
  border-radius: 10px !important;
  max-width: 209px; 
  box-shadow: none;
  border: 1px solid transparent;
  height: 48px;
  font-family: "Gotham-Medium", "Courier New", Courier, monospace !important;
  font-weight:350 !important
}
.closeImage img:hover {
  background-color: #f0f0f0;
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); 
}
#requester-contact.MuiInputBase-input.MuiOutlinedInput-input{
  color:#98A2B3 !important;
}
#requester-email.MuiInputBase-input.MuiOutlinedInput-input{
  color:#98A2B3 !important;
}